<template>
  <div>
    <section
      class="locations-page"
      v-if="this.$route.params.state == undefined && this.$route.params.city == undefined"
    >
      <h1>EV Charging Stations in the USA</h1>
      <div class="locations-page-wrapper">
        <ul v-for="(value, key) in getStateLocations" :key="key">
          <li @click="redirectToState(key)" v-if="value.length > 0">
            <a
              ><strong>{{ key }}</strong> ({{ value.length }})</a
            >
          </li>
          <li v-else>
            <span
              ><strong>{{ key }}</strong> ({{ value.length }})</span
            >
          </li>
        </ul>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Locations',
  metaInfo: {
    title: 'Charging Station Locations | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'The charging station directory includes all Electrify America charging station locations in the United States.',
      },
    ],

    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/locations/' }],
  },
  async created() {
    await this.$store.dispatch('locations/getAllLocations', this.$router);
  },
  computed: {
    ...mapState('locations', ['filterStateLocations']),
    getStateLocations() {
      const locations = { ...this.filterStateLocations, Alaska: [], Vermont: [], 'West Virginia': [] };
      const sortLocations = Object.keys(locations)
        .sort()
        .reduce((obj, key) => {
          obj[key] = locations[key];
          return obj;
        }, {});
      return sortLocations;
    },
  },
  methods: {
    redirectToState(state) {
      const trimState = state?.replaceAll(' ', '-').toLowerCase();
      this.$router.push({ name: 'locations-state-en', params: { state: trimState } });
    },
  },
};
</script>

<style lang="scss" scoped>
.locations-page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  min-height: calc(100vh - 550px);
  padding-left: 10px;
  &-wrapper {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    ul {
      padding-left: 8px;
    }
    li {
      list-style-type: none;
      display: inline-block;
      a {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .locations-page {
    padding: 16px;
    &-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
</style>
